import linkTarget from "./linkTarget";
import groqTwoThirdsStack from "./twoThirdsStack";
import groqImage, { groqImageInlineAsset } from "./image";
import groqFile from "./file";
import groqCallToAction from "./callToAction";
import groqWithPortableLink from "./withPortableLink";
import markDefs from "./markDefs";

/**
 * Use for blockContent Sanity types (full magazin-like content)
 * TODO BRN-518 Cleanly map other richText types to less feature
 * packed versions of groqBlockContent / groqWithPortableLink
 * We could also change out general groq helper structure to
 * something like:
 * import { blockContent, simpleRichText } from "~/groq/richText"
 *
 */
const groqBlockContent = (
  fieldNames: string[] = ["body"],
  nestingLevel: number = 1,
): string =>
  fieldNames
    .map(
      (fieldName) =>
        groq`${fieldName}[isHidden != true]{
          ...,
          ${linkTarget},
          ${markDefs},
          ${groqTwoThirdsStack},
          ${groqWithPortableLink(["info"])},

          _type == "brandsRow" => @{
            ...,
            "brands": brands[] { ..., ${groqImageInlineAsset}}
          },

          _type == "callToAction" => {
            ...,
            ${linkTarget},
            pageReference->{ _type, slug }
          },

          _type == 'cardsRow' => @{
            ...,
            cards[] {
              ...,
              ${groqCallToAction},
              ${groqImage("image")}
            },
          },

          _type == 'overlayCards' => @{
            ...,
            cards[] {
              ...,
              ${groqImage("image")}
            },
          },

          _type == 'faqList' => @{
            ...,
            'faqArticles': coalesce(faqArticles[] -> {..., ${
              nestingLevel > 0
                ? groqBlockContent(["body"], nestingLevel - 1)
                : groqWithPortableLink(["body"])
            }}, []),
          },

          _type == "infoSection" => @{
            ...,
            info[] {
              ...,
              ${markDefs}
            },
            ${groqImage("heroImage")},
            ${groqImage("smallImage")},
            ${groqCallToAction},
          },

          _type == "singleAnimation" => @{
            ...,
            ${groqFile("image")}
          },

          _type == "singleImage" => {
            ${groqImage("image")},
          },

          _type == 'tariffCalculator' => @{
            ...,
            "calculator": coalesce(calculator->{
                'businessCustomerPreSelected': coalesce(businessCustomerPreSelected, false),
                'canSwitchBusinessCustomer': coalesce(canSwitchBusinessCustomer, false),
                partnerTariffId,
                tabs[] {
                title,
                icon,
                tariffs[] -> {
                    tariffSubType,
                    runTimeType,
                    uniqueSellingPoints,
                    tariffType,
                },
                },
            }, {})
          },

          _type == "youtubeVideo" => @{
            ...,
            ${groqImage("posterImage")}
          },

        }`,
    )
    .join(",");

export default groqBlockContent;
