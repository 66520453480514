import linkTarget from "./linkTarget";
import markDefs from "./markDefs";

export default (fieldNames: string[] = ["info"]): string =>
  fieldNames
    .map(
      (fieldName) =>
        groq`${fieldName}[]{
          ...,
          _type == "callToAction" => {
            ...,
            ${linkTarget},
            pageReference->{ _type, slug }
          },
          ${linkTarget},
          ${markDefs}
        }`,
    )
    .join(",");
