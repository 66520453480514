import groqWithPortableLink from "~/groq/withPortableLink";
import groqCallToAction from "~/groq/callToAction";
import groqImage from "~/groq/image";

export default /* groq */ `_type == "twoThirdsStack" => {
    ...,
  "twoThirdsRows": rows[_type == "twoThirdsRow"] {
    ...,
    ${groqWithPortableLink(["info"])},
    ${groqCallToAction},
    ${groqImage("image")},
    ${groqImage("posterImage")}
  },
}`;
